import React, { Suspense, useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/login";
import { LeftSidebar } from "./layout-blueprints";
import useStore from "./context";
import * as Api from "./service";
import { SuspenseLoading } from "components";
import {
  PasswordChangeForm,
  GoodTypesList,
  GoodTypesForm,
  GoodList,
  GoodForm,
  Devices,
  DevicesForm,
  Users,
  UsersForm,
  SliderForm,
  Slider,
  Faq,
  FaqForm,
  Support,
  SupportForm,
  AboutContentForm,
  RequestedBranch,
  PrivacyPolicy,
  TermsAndConditions,
  GoodsRequests,
  UserDetails,
  Media,
  AdvertisementsList,
  AdvertisementForm,
} from "./pages";

import Slider2 from "./pages/home/v2/slider";
import Benefit from "./pages/home/v2/benefit";
import StepsSection from "./pages/home/v2/steps";
import InfoImage from "./pages/home/v2/InfoImage";
import ItemsSection from "./pages/home/v2/itemsSection";
import ClientsSection from "./pages/home/v2/clientsSection";
import MobileSection from "./pages/home/v2/mobileSection";
import ManagementSection from "./pages/home/v2/managementSection";
import ContactSection from "./pages/home/v2/contactSection";
import FooterSection from "./pages/home/v2/footerSection";
import Contact from "./pages/contact/list";

const Routes = () => {
  const [show, setShow] = useState(false);
  const { user, setUser } = useStore();

  useEffect(() => {
    if (sessionStorage.getItem("token") || localStorage.getItem("token")) {
      Api.main
        .getuser()
        .then((user) => {
          setUser(user);
          setShow(true);
        })
        .catch((e) => {
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");
          setShow(true);
        });
    } else {
      setShow(true);
    }
  }, [setUser]);

  return (
    show && (
      <Suspense fallback={<SuspenseLoading />}>
        {!user ? (
          <Login />
        ) : (
          <LeftSidebar>
            <Switch>
              <Route
                exact
                path="/password-change"
                component={PasswordChangeForm}
              />
              <Route
                exact
                path="/goods-type/create/:id?"
                component={GoodTypesForm}
              />
              <Route exact path="/goods-type" component={GoodTypesList} />

              <Route exact path="/goods/create/:id?" component={GoodForm} />
              <Route exact path="/goods" component={GoodList} />
              <Route
                exact
                path="/devices/create/:id?"
                component={DevicesForm}
              />
              <Route exact path="/devices" component={Devices} />
              <Route exact path="/users/create/:id?" component={UsersForm} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/user-details" component={UserDetails} />
              <Route
                exact
                path="/home/slider/create/:id?"
                component={SliderForm}
              />
              <Route exact path="/home/slider" component={Slider} />

              <Route exact path="/home/faq/create/:id?" component={FaqForm} />
              <Route exact path="/home/faq" component={Faq} />
              <Route exact path="/home/support" component={Support} />
              <Route
                exact
                path="/home/support/create/:id?"
                component={SupportForm}
              />
              <Route
                exact
                path="/home/about-content"
                component={AboutContentForm}
              />
              <Route
                exact
                path="/branch-requests"
                component={RequestedBranch}
              />
              <Route
                exact
                path="/home/privacy-policy"
                component={PrivacyPolicy}
              />
              <Route
                exact
                path="/home/terms-and-conditions"
                component={TermsAndConditions}
              />
              <Route exact path="/goods-requests" component={GoodsRequests} />

              <Route exact path="/messages" component={Contact} />
              <Route exact path="/media" component={Media} />
              <Route
                exact
                path="/advertisements"
                component={AdvertisementsList}
              />
              <Route
                exact
                path="/advertisements/create"
                component={AdvertisementForm}
              />
              <Route
                exact
                path="/advertisements/create/:id"
                component={AdvertisementForm}
              />

              <Route exact path="/home/v2/slider" component={Slider2} />
              <Route exact path="/home/v2/benefit" component={Benefit} />
              <Route exact path="/home/v2/steps" component={StepsSection} />
              <Route
                exact
                path="/home/v2/image-section"
                component={InfoImage}
              />
              <Route
                exact
                path="/home/v2/items-section"
                component={ItemsSection}
              />
              <Route
                exact
                path="/home/v2/clients-section"
                component={ClientsSection}
              />
              <Route
                exact
                path="/home/v2/mobile-section"
                component={MobileSection}
              />
              <Route
                exact
                path="/home/v2/management-section"
                component={ManagementSection}
              />
              <Route
                exact
                path="/home/v2/contact-section"
                component={ContactSection}
              />
              <Route
                exact
                path="/home/v2/footer-section"
                component={FooterSection}
              />
            </Switch>
          </LeftSidebar>
        )}
      </Suspense>
    )
  );
};

export default Routes;
