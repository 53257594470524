import React, { useEffect } from "react";
import { WrapperSeamless } from "../../layout-components";
import Table from "../../components/tables/table";
import { Button } from "@material-ui/core";
import { useGetList } from "../../hooks";
import { Select } from "../../components";
import { Link } from "react-router-dom";
import * as Api from "../../service";
import useStore from "../../context";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "localization/translation";
import { PLACEMENT_FILTER_DROPDOWN, STATUS_DROPDOWN } from "Constants";
import { dateFormatter } from "helpers/dateFormatter";

const baseImageUrl = process.env.REACT_APP_imagesBaseUrl;

export default function AdvertisementsList() {
  const { setRemove, language, query, setQuery } = useStore();
  const translation = useTranslation();

  const limit = 20;
  const isPageCorrect = true;

  const [items, total, refresh, setItems] = useGetList(
    Api.advertisement.getAll,
    query,
    limit,
    isPageCorrect
  );

  const handleStatusChange = (val) => {
    setQuery((st) => ({ ...st, status: val }));
  };

  const handlePlacementChange = (val) => {
    setQuery((st) => ({ ...st, placement: val }));
  };

  const handleDelete = (id) => {
    setRemove(() => () => Api.advertisement.delete(id).then(refresh));
  };

  useEffect(() => {
    if (language && `name_${language}` !== query.sort) {
      setQuery((prev) => ({
        ...prev,
        sort: `name_${language}`,
      }));
    }
  }, [language, query.sort, setQuery]);

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <img
          className="suppport-icon"
          src={`${baseImageUrl}${
            item?.[`image_${language}`]
              ? item?.[`image_${language}`]
              : item?.image_en
          }`}
          alt="Icon"
        />
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item?.[`title_${language}`]}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {item.start_time ? dateFormatter(item.start_time, "DD/MM/YYYY") : ""}
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {item.end_time ? dateFormatter(item.end_time, "DD/MM/YYYY") : ""}
        </div>
      </td>
      <td className="text-right actions_filed">
        <Link to={`/advertisements/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() => handleDelete(item.id)}
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
        >
          <DeleteIcon color="error" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        noDrag
        title={translation.advertisements}
        items={items}
        setItems={setItems}
        total={total}
        limit={limit}
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.image}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.title}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.start_date}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.end_date}</span>
            </th>
          </>
        }
        header={
          <>
            <Select
              onChange={(val) => handleStatusChange(val)}
              value={query?.status ?? []}
              field={"name"}
              label={translation.status}
              noCard
              options={STATUS_DROPDOWN}
              className="ml-4 mr-auto w-25"
            />
            <Select
              onChange={(val) => handlePlacementChange(val)}
              value={query?.placement ?? []}
              field={"name"}
              label={translation.placement}
              noCard
              options={PLACEMENT_FILTER_DROPDOWN}
              className="ml-4 mr-auto w-25"
              multiple
            />
          </>
        }
        rows={rows}
        createLink="advertisements/create"
      />
    </WrapperSeamless>
  );
}
