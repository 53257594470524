import React, { memo } from "react";
import { useDropzone } from "react-dropzone";

import { Card, Button, List, ListItem } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";
import CheckIcon from "@material-ui/icons/Check";

import { useTranslation } from "localization/translation";
import useStore from "context";
import { FILE_MAX_SIZE } from "Constants";

const Upload = ({
  single,
  setNewFile,
  accept,
  isAdvertisement,
  uploadedImage,
}) => {
  const translation = useTranslation();
  const { setErrorMsg } = useStore();

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept,
    multiple: !single,
    onDrop: (acceptedFiles) => {
      const oversizedFiles = acceptedFiles.filter(
        (file) => file.size > FILE_MAX_SIZE
      );

      if (oversizedFiles.length > 0) {
        return setErrorMsg(translation.image_size_error_msg);
      }

      setNewFile(acceptedFiles);
    },
  });

  const files = acceptedFiles.map((file) => (
    <ListItem
      className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center"
      key={file.path}
    >
      <span>{file.path}</span>{" "}
      <span className="badge badge-pill bg-neutral-warning text-warning">
        {file.size} bytes
      </span>
    </ListItem>
  ));

  const uploadedImageFiles =
    Array.isArray(uploadedImage) &&
    uploadedImage?.map((file) => (
      <ListItem
        className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center"
        key={file.path}
      >
        <span>{file.path}</span>{" "}
        <span className="badge badge-pill bg-neutral-warning text-warning">
          {file.size} bytes
        </span>
      </ListItem>
    ));

  return (
    <Card className="mt-2 p-2 p-lg-3 shadow-xxl">
      <div className="dropzone">
        <div {...getRootProps({ className: "dropzone-upload-wrapper" })}>
          <input {...getInputProps()} />
          <div className="dropzone-inner-wrapper py-4">
            {isDragAccept && (
              <div>
                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                  <CheckIcon className="d-50" />
                </div>
                <div className="font-size-sm text-success">
                  {single
                    ? translation.file_will_be_uploaded
                    : translation.all_files_will_be_uploaded}
                </div>
              </div>
            )}
            {isDragReject && (
              <div>
                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                  <CloseTwoToneIcon className="d-50" />
                </div>
                <div className="font-size-sm text-danger">
                  {translation.some_files_will_be_rejected}
                </div>
              </div>
            )}
            {!isDragActive && (
              <div>
                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                  <CloudUploadTwoToneIcon className="d-50" />
                </div>
                <div className="font-size-sm">
                  {translation.drag_and_drop}{" "}
                  {single ? translation.file : translation.files} here{" "}
                  <span className="font-size-xs text-dark">
                    {translation.jpg_png_images}
                  </span>
                </div>
              </div>
            )}

            <small className="py-2 text-black-50">or</small>
            <div>
              <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                <span className="px-2">
                  {translation.browse}{" "}
                  {single ? translation.file : translation.files}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="font-weight-bold my-4 text-uppercase text-dark font-size-sm text-center">
          {single ? translation.file : translation.files}{" "}
          {translation.for_upload}
        </div>
        {files.length <= 0 && (
          <div className="text-info text-center font-size-sm">
            {translation.chosen} {single ? translation.file : translation.files}{" "}
            {translation.will_appear_here}
          </div>
        )}
        {isAdvertisement
          ? uploadedImage &&
            typeof uploadedImage === "object" && (
              <div>
                <Alert severity="success" className="text-center mb-3">
                  {translation.success} {translation.files_click}{" "}
                  <b>{translation.save}</b> {translation.for_upload}
                </Alert>
                <List component="div" className="font-size-sm">
                  {uploadedImageFiles}
                </List>
              </div>
            )
          : files.length > 0 && (
              <div>
                <Alert severity="success" className="text-center mb-3">
                  {translation.success} <b>{files.length}</b>{" "}
                  {translation.files_click} <b>{translation.save}</b>{" "}
                  {translation.for_upload}
                </Alert>
                <List component="div" className="font-size-sm">
                  {files}
                </List>
              </div>
            )}
      </div>
    </Card>
  );
};

export default memo(Upload);
