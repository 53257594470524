/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { Container, TextField, Card, Box } from "@material-ui/core";

import {
  useText,
  useGetData,
  usePutData,
  usePostData,
  useGetList,
} from "hooks";
import useStore from "context";
import * as Api from "service";
import Gallery from "components/gallery";
import { Lang, MultiSelect, Save, Select } from "components";
import { useTranslation } from "localization/translation";
import {
  LANGUAGES_ARRAY,
  PLACEMENT_DROPDOWN,
  STATUS_DROPDOWN,
} from "Constants";
import { dateFormatter } from "helpers/dateFormatter";

const { RangePicker } = DatePicker;

const initForm = {
  title_en: "",
  title_hy: "",
  title_ru: "",
  short_description_en: "",
  short_description_hy: "",
  short_description_ru: "",
  link: "",
  image_en: "",
  image_hy: "",
  image_ru: "",
  placement: [],
  status: "",
  country_ids: [],
  start_time: "",
  end_time: "",
};

const dateFormat = "YYYY-MM-DD";

const placementKeys = [
  "goods_list_3rd",
  "goods_list_5th",
  "inventory_list_3rd",
  "inventory_list_5th",
  "advertisement_list",
];

const EMPTY_DATAS_KEYS = [
  "short_description_en",
  "short_description_hy",
  "short_description_ru",
  "link",
];

const Form = () => {
  const { id } = useParams();
  const isPageCorrect = true;
  const limit = 20;
  const { setErrorMsg, lang, query } = useStore();

  const [data, , refresh] = useGetData(id && Api.advertisement.getById, id);

  const postCallback = usePostData(Api.advertisement.create, refresh);
  const updateCallback = usePutData(Api.main.updateAdvertisement, refresh);
  const [countries] = useGetList(
    Api.countries.getAll,
    query,
    limit,
    isPageCorrect
  );

  const [newFiles, setNewFiles] = useState({});
  const [date, setDate] = useState({
    start_time: null,
    end_time: null,
  });
  const translation = useTranslation();

  const [text, setText, , input] = useText(initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      const formData = new FormData();

      if (LANGUAGES_ARRAY.some((el) => !text[`title_${el}`]))
        return setErrorMsg(translation.required_fields_error_msg);

      if (!text[`image_en`]) return setErrorMsg(translation.image_error_msg);

      if (text.country_ids.length === 0)
        return setErrorMsg(translation.country_error_msg);

      const data = {};

      for (let key in text) {
        if (key === "placement") {
          placementKeys.forEach((elem) => {
            data[elem] = text[key]?.includes(elem);
          });
        } else if (key.includes("image") && text[key]) {
          data[key] = Array.isArray(text[key]) ? text[key][0] : text[key];
        } else if (key === "status" && !!text.start_time) {
          continue;
        } else if ((key === "start_time" || key === "end_time") && !text[key]) {
          continue;
        } else if (key === "country_ids") {
          const numericIds = text[key]?.map((id) => Number(id));
          data[key] = numericIds;
        } else if (text[key]) {
          data[key] = text[key];
        } else if (EMPTY_DATAS_KEYS.includes(key)) {
          data[key] = "";
        }
      }

      for (let key in data) {
        if (Array.isArray(data[key])) {
          data[key].forEach((el) => {
            formData.append(`${key}[]`, el);
          });
        } else {
          formData.append(key, data[key]);
        }
      }

      id ? updateCallback(formData, id) : postCallback(formData);
    },
    [setErrorMsg, text, newFiles]
  );

  const handleChangeStatus = (id) => {
    setText((st) => ({ ...st, status: id }));
    setText((st) => ({ ...st, start_time: "" }));
    setText((st) => ({ ...st, end_time: "" }));
  };

  const handleChangePlacement = (id) => {
    setText((st) => ({ ...st, placement: id }));
  };

  const handleChangeCountries = (ids) => {
    setText((st) => ({ ...st, country_ids: ids }));
  };

  const handleChangeValue = (e, maxLength) => {
    const { name, value } = e.target;

    if (value.length <= maxLength) {
      setText((prevText) => ({
        ...prevText,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (event) => {
    const formattedDates = event?.map((dateObject, index) => {
      const year = dateObject.$y;
      const month = dateObject.$M;
      const day = dateObject.$d.getDate();
      const date = new Date(year, month, day);

      if (index === 0) {
        date.setHours(0, 0, 0, 0);
      } else if (index === 1) {
        date.setHours(23, 59, 59, 999);
      }

      const formattedDate = date.toISOString();

      return formattedDate;
    });

    setDate({
      start_time: formattedDates ? formattedDates[0] : "",
      end_time: formattedDates ? formattedDates[1] : "",
    });

    setText((st) => ({
      ...st,
      start_time: formattedDates ? formattedDates[0] : "",
      end_time: formattedDates ? formattedDates[1] : "",
    }));
  };

  const disablePastDates = (current) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return current && current < now;
  };

  useEffect(() => {
    if (data) {
      const transformData = (data) => {
        const transformedData = {
          title_en: data?.title_en ?? "",
          title_hy: data?.title_hy ?? "",
          title_ru: data?.title_ru ?? "",
          short_description_en: data?.short_description_en ?? "",
          short_description_hy: data?.short_description_hy ?? "",
          short_description_ru: data?.short_description_ru ?? "",
          link: data?.link ?? "",
          image_en: data?.image_en ?? "",
          image_hy: data?.image_hy ?? "",
          image_ru: data?.image_ru ?? "",
          placement: placementKeys.filter((key) => data?.[key]),
          status: data?.status ?? "",
          start_time: data?.start_time ?? "",
          end_time: data?.end_time ?? "",
          country_ids: data?.country_ids ?? [],
        };

        setDate({
          start_time: data?.start_time,
          end_time: data?.end_time,
        });
        return transformedData;
      };

      const editData = transformData(data);
      setText(editData);
    }
  }, [data]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <Gallery
            single
            label={translation.image}
            setNewFile={(e) => {
              setNewFiles((prev) => ({ ...prev, [`image_${lang}`]: e }));
              setText((st) => ({ ...st, [`image_${lang}`]: e }));
            }}
            value={text[`image_${lang}`] ? text[`image_${lang}`] : null}
            onChange={() => setText((st) => ({ ...st, [`image_${lang}`]: "" }))}
            accept="image/*"
            isAdvertisement={true}
            uploadedImage={text[`image_${lang}`]}
          />
          <RangePicker
            onChange={(date) => handleDateChange(date)}
            disabledDate={disablePastDates}
            value={
              date.start_time &&
              date.end_time && [
                dayjs(dateFormatter(date.start_time, dateFormat), dateFormat),
                dayjs(dateFormatter(date.end_time, dateFormat), dateFormat),
              ]
            }
          />

          <TextField
            {...input(`title_${lang}`)}
            label={translation.title}
            onChange={(e) => handleChangeValue(e, 255)}
          />
          <TextField
            {...input(`short_description_${lang}`)}
            onChange={(e) => handleChangeValue(e, 500)}
            required={false}
            label={translation.description}
          />
          <TextField
            {...input(`link`)}
            label={translation.link}
            required={false}
            onChange={(e) => handleChangeValue(e, 255)}
          />
          <Select
            onChange={(val) => handleChangeStatus(val)}
            value={text.status}
            field={"name"}
            label={translation.status}
            noCard
            options={STATUS_DROPDOWN.map((option) => ({
              ...option,
              disabled: option.id === "scheduled",
            }))}
          />
          <Select
            onChange={(val) => handleChangePlacement(val)}
            value={text?.placement}
            field={`name`}
            label={translation.placement}
            noCard
            options={PLACEMENT_DROPDOWN}
            multiple
          />
          {!!countries?.length && (
            <MultiSelect
              field={`name_${lang}`}
              label={`${translation.countries}*`}
              data={countries}
              value={text?.country_ids}
              onChange={(val) => handleChangeCountries(val)}
            />
          )}
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
