export const IMAGE_ERROR_MESSAGE = "Choose the image";

export const CONFIRMED = "confirmed";
export const CANCELED = "canceled";
export const FILE_MAX_SIZE = 5 * 1024 * 1024;

export const requestMessages = {
  something_went_wrong: "Something went wrong",
};

export const ALL_FIELDS_REQUIRED = "All names required";

export const LANGUAGES = {
  arm: "hy",
  rus: "ru",
  eng: "en",
};

export const BENEFITS = "benefit";
export const CLIENTS_SECTION = "clients-section";
export const ITEM_SECTION = "item-section";
export const IMAGE_INFO = "infoImage";
export const FOOTER_SECTION = "footer-section";

export const ROUTES = {
  user_details: "/user-details",
};
export const USER_ID = "userId";

export const BUTTONS_TYPE = {
  active: "activate",
  deactive: "deactivate",
  delete: "delete",
  edit: "edit",
  cancel: "cancel",
  save: "save",
};

export const BUTTON_VARIANTS = {
  contained: "contained",
};

const USER_STATUS = {
  PENDING: "pending",
  ACCEPTED: "active",
  REJECTED: "rejected",
  DEACTIVATED: "deactivated",
  DELETED: "deleted",
};

export const statusList = {
  1: USER_STATUS.PENDING,
  2: USER_STATUS.ACCEPTED,
  3: USER_STATUS.REJECTED,
  4: USER_STATUS.DEACTIVATED,
  5: USER_STATUS.DELETED,
};

export const HTTP_REQUEST_STATUS = {
  BAD_REQUEST: 400,
};

export const SOMETHING_WENT_WRONG = "Something went wrong";

export const ACTIVE = "Active";
export const DEACTIVATED = "Deactivated";

export const DEFAULT_REG_NUMBER = "-";

export const ACTIVE_STATUS_VALUE = 1;
export const INACTIVE_STATUS_VALUE = 0;
export const ACTIVE_STATUS = "activate";
export const INACTIVE_STATUS = "deactivate";
export const BRANCH_ERROR_MSG = "There are already created branches";
export const PAGE_LANGUAGE = "page-lang";
export const FILE = "file";
export const GOODS = "goods";
export const LANGUAGES_ARRAY = ["hy", "ru", "en"];

export const MEASUREMENT_OPTIONS_TEMPORARY = [
  {
    id: 1,
    name_en: "Milliliters",
    name_ru: "Миллилитр",
    name_hy: "Միլիլիտր",
    value_en: "ML",
    value_ru: "МЛ",
    value_hy: "ՄԼ",
  },
  {
    id: 4,
    name_en: "Kilograms",
    name_ru: "Килограмм",
    name_hy: "Կիլոգրամ",
    value_en: "KG",
    value_ru: "КГ",
    value_hy: "ԿԳ",
  },
  {
    id: 3,
    name_en: "Pieces",
    name_ru: "Штуки",
    name_hy: "Կտորներ",
    value_en: "PC",
    value_ru: "ШТ",
    value_hy: "ՀՏ",
  },
];

export const MILLILITERS_VALUE = 1;

export const WEB_CONTENT_PATH = "/home";
export const EMAIL_ERROR_MESSAGE = "Invalid email";

export const NUMBER_REGEX = /[^0-9]/g;
export const NUMBER_DOT_REGEX = /[^0-9.]/g;
export const ONE_DOT_REGEX = /(\..*)\./g;

export const STATUS_DROPDOWN = [
  {
    id: "active",
    name: "Active",
  },
  {
    id: "inactive",
    name: "Inactive",
  },
  {
    id: "scheduled",
    name: "Scheduled",
  },
];

export const PLACEMENT_DROPDOWN = [
  {
    id: "goods_list_3rd",
    name: "Goods List 3rd Place",
  },
  {
    id: "goods_list_5th",
    name: "Goods List 5th Place",
  },
  {
    id: "inventory_list_3rd",
    name: "Inventory List 3rd Place",
  },
  {
    id: "inventory_list_5th",
    name: "Inventory List 5th Place",
  },
  {
    id: "advertisement_list",
    name: "Advertisement List",
  },
];

export const PLACEMENT_FILTER_DROPDOWN = [
  {
    id: "GL3",
    name: "Goods List 3rd Place",
  },
  {
    id: "GL5",
    name: "Goods List 5th Place",
  },
  {
    id: "IL3",
    name: "Inventory List 3rd Place",
  },
  {
    id: "IL5",
    name: "Inventory List 5th Place",
  },
  {
    id: "AL",
    name: "Advertisement List",
  },
];
