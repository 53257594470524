export const localizationArmenian = {
  about_us: "Մեր Մասին",
  about: "Գլխավոր",
  how_it_work: "Ինչպես է աշխատում",
  how_it_works: "Ինչպես է աշխատում",
  contacts_us: "Կապնվել մեզ հետ",
  faq: "ՀՏՀ",
  dashboard: "Վահանակ",
  log_out: "Դուրս գալ",
  product: "Պրոդուկտ",
  terms_of_use: "Օգտագործման կանոններ",
  privacy_policy: "Գաղտնիության քաղաքականություն",
  mobile_app: "Բջջային հավելված",
  management_tool: "Կառավարման գործիքներ",
  bar_control: "Bar Control",
  owner_operator_company_name: "Owner Operator/Company Name",
  e_mail: "E-Mail",
  phone_number: "Հեռախոսահամար",
  comment: "Comment",
  request_a_callback: "Request a Callback",
  frequently_asked_questions: "Frequently Asked Questions",
  branch_management: "Մասնաճյուղի կառավարում",
  branches: "Մասնաճյուղերը",
  request_for_new_branch: "Նոր մասնաճյուղի հայտ",
  manager_invitations: "Մենեջերի հրավերներ",
  managers: "Մենեջերներ",
  manager: "Մենեջեր",
  barmen_list: "Բարմենների ցուցակ",
  barmen: "Բարմեն",
  goods_type: "Ապրանքի տեսակ",
  goods: "Ապրանքներ",
  inventories: "Գույքագրումներ",
  navigation_menu: "Նավիգացիոն մենյու",
  items: "Ցանկ",
  name: "Անվանում",
  phone: "Հեռախոսահամար",
  email: "Էլեկտրոնային հասցե",
  thank_you_email_is_verified:
    "Շնորհակալություն,էլեկտրոնային հասցեն հաստաված է",
  address: "Հասցե",
  latitude: "Լայնություն",
  longitude: "Երկայնություն",
  actions: "Գործողություններ",
  action: "Գործողություն",
  active: "Ակտիվ",
  inactive: "Ոչ ակտիվ",
  not_active: "not active",
  branch_requests: "Մասնաճյուղերի հարցում",
  create: "Ստեղծել",
  requested_branches_count: "Հայցվող մասնաճյուղերը քանակ",
  message: "Հաղորդագրություն",
  status: "Կարգավիճակ",
  barcode: "Շտրիխ կոդ",
  full_bottle: "Full Bottle",
  bottle_count: "Bottle count",
  left_over_ml: "Left over (ml)",
  created_at: "Ստեղծվել է՝",
  updated_at: "Updated at",
  deleted_at: "Deleted at",
  count_of_bottles: "Count of bottles",
  left_over: "Left over",
  save: "Պահպանել",
  save_filter: "Save filter",
  sign_in: "Մուտք գործել",
  sign_up: "Գրանցվել",
  role: "Դեր",
  branch_name: "Մասնաճյուղի անուն",
  created: "Ստեղծվել է",
  full_name: "Full Name",
  company_name: "Ընկերության Անվանումը",
  company_business_address: "Company business Address",
  branch_count: "Մասնաճյուղերի քանակ",
  company_email: "Company Email",
  tax_code: "Հարկային կոդը",
  registration_number: "Registration Number (optional)",
  goods_types: "Ապրանքի տեսակ",
  weight_full: "Ամբողջական քաշ",
  weight_empty: "Դատարկ քաշ",
  select_branch: "Ընտրել մասնաճյուղը",
  type: "Տեսակ",
  in_progress: "In progress",
  complete: "Ավարտել",
  branch: "Մասնաճյուղ",
  order: "Պատվեր",
  newest: "Newest",
  oldest: "Oldest",
  email_address: "Email Address",
  fill_the_address: "Fill the address",
  search_terms: "Search terms...",
  search_orders: "Search orders...",
  password: "Գաղտնաբառ",
  repeat_password: "Repeat password",
  token_is_expired: "Թոքենը ժամկետանց է",
  goods_name: "Ապրանքների անվանում",
  restaurant_name: "Ռեստորանի անվանում",
  scales: "Կշեռքներ",
  all_statuses: "All statuses",
  pending: "Ընթացքում",
  completed: "Ավարտված",
  rejected: "Մերժված",
  processing: "Processing",
  cancelled: "Cancelled",
  branches_count: "Մասնաճյուղերի քանակ",
  date: "Ամսաթիվ",
  details: "Մանրամասներ",
  goods_requests: "Ապրանքների հարցում",
  good_detail: "Ապրանքի տվյալները",
  s_n: "S/N",
  manufacturer: "Արտադրող",
  production_date: "Արտադրման ամսաթիվ",
  mac: "Մակ հասցե",
  is_active: "Ակտիվ Է",
  change_user_status: "Are you sure you want to change the user status?",
  reject: "Մերժել",
  accept: "Ընդունել",
  text: "Տեքստ",
  contact: "Contact",
  view: "Դիտել",
  image: "Image",
  slides: "Slides",
  close_drawer: "Close drawer",
  bamburgh_pro: "Bamburgh React Admin Dashboard with Material-UI PRO",
  collapse_sidebar: "Փակել վահանակը",
  expand_sidebar: "Ընդլայնել վահանակը",
  cancel: "Չեղարկել",
  want_to_reject: "Դուք վստահ եք,որ ուզում եք մերժել",
  want_to_accept: "Դուք վստահ եք,որ ուզում եք ընդունել",
  confirmed: "Հաստատված",
  analytics: "Analytics",
  statistics: "Statistics",
  overview: "Overview",
  back_to_dashboard: "Back to dashboard",
  chat: "Chat",
  calendar: "Calendar",
  file_manager: "File Manager",
  projects: "Projects",
  profile: "Պրոֆիլ",
  profile_completion: "Profile completion",
  monitoring_dashboard: "Monitoring Dashboard",
  analytics_dashboard: "Analytics Dashboard",
  statistics_dashboard: "Statistics Dashboard",
  view_profile: "View profile",
  web_content: "Վեբ բովանդակություն",
  users: "Օգտատերեր",
  messages: "Հաղորդագրություններ",
  terms_and_conditions: "Ընդհանուր դրույթներ և պայմաններ",
  main_slider: "Գլխավոր վահանակ",
  benefit_section: "Օգտավետություն",
  steps_section: "Քայլեր",
  image_section: "Նկարներ",
  items_section: "Նկարագրություն",
  clients_section: "Հաճախորդներ",
  mobile_section: "Բջջային",
  management_section: "Կառավարում",
  contact_section: "Կապ",
  footer_section: "Ստորին բաժին",
  learn_more: "Learn more",
  accountant_profile: "This is an accountant profile",
  logout: "Logout",
  tasks: "Tasks",
  reports: "Reports",
  stats: "Stats",
  server_stats: "Server Stats",
  view_more_items: "View more items",
  sales_target: "Today's sales target",
  daily_statistics: "Daily statistics regarding your sales targets",
  generate_report: "Generate report",
  view_complete_report: "View complete report",
  mistaken_idea: "But I must explain to you how all this mistaken idea.",
  wonderful_serenity: "A wonderful serenity has taken possession.",
  presidential_scandal: `Bill Clinton's presidential scandal makes it
  online.`,
  java_exam_day: "Java exam day",
  business_investor_meeting: "Business investor meeting",
  new: "New",
  graphical_browser: `Mosaic, the first graphical browser, is
                                introduced to the average consumer.`,
  own_image: "Who formed us in his own image, and the breath.",
  dummy_text_acting: "This is a dummy text acting ...",
  files_ready: "Files ready",
  add: "Add",
  decrease: "decrease",
  cards_examples: "Cards examples",
  form_wizards: "Form wizards",
  pricing_tables: "Pricing tables",
  dashboards: "Dashboards",
  there_are: "There are",
  multiple: "multiple",
  dashboard_layouts_available: "dashboard layouts available!",
  helpdesk: "Helpdesk",
  crm_ui: "CRM UI",
  customers: "Հաճախորդներ",
  customer: "Հաճախորդ",
  delete: "Ջնջել",
  menu_box: "This menu box is scrollable (sm)",
  services: "Services",
  others: "Others",
  components: "Components",
  view_details: "View details",
  web_developer: "Web developer",
  javascript: "Javascript",
  angular: "Angular",
  incapable_of_drawing: `I should be incapable of drawing a single stroke at the
                  present moment; and yet I feel that I never was a greater
                  artist than now.`,
  team_members: "Team members",
  view_complete_profile: "View complete profile",
  wonderful_serenity_has_possession: "Wonderful serenity has possession",
  please_wait_while_load: "Please wait while we load the live preview examples",
  preview_instance: `This live preview instance can be slower than a real
                                production build!`,
  add_new_entry: "Add new entry",
  stay_up_to_date: "Stay up to date",
  subscribe_to_stay_up_to_date:
    "Subscribe to stay up to date with our latest releases and updates!",
  results_per_page: "results per page",
  ascending: "Ascending",
  descending: "Descending",
  order_details: "Order details",
  source: "Source",
  order_id: "Order ID",
  balance: "Balance",
  amount: "Amount",
  fiat_deposit: "Fiat deposit",
  bank_account: "Bank Account",
  deposit: "Deposit",
  paypal_withdraw: "Paypal withdraw",
  crypto_wallet: "Crypto Wallet",
  my_bitcoin_wallet: "My Bitcoin Wallet",
  withdraw: "Withdraw",
  current_month: "Current month",
  your_daily_tasks_list: "Your daily tasks list",
  finish_tasks_for_today: "Finish tasks for today",
  submit_report: "Submit Report",
  get_in_touch: "Get in touch",
  MySpace_network: "MySpace becomes the most popular social network.",
  pending_actions: "You have pending actions to take care of.",
  total_sales: "Total Sales",
  total_performance: "Total performance for selected period",
  world_among_the_stalks: "World among the stalks",
  deploy_new_website: "Deploy new website",
  comments_available: "There are 653 new comments available!",
  latest_sales: "Latest sales",
  increase: "Increase",
  last_year: "Last year",
  new_messages: "new messages",
  world_wide_web: `The World Wide Web goes live with its first web
                            page.`,
  clinton_presidential: "Bill Clinton's presidential scandal makes it online.",
  first_graphical_browser: `Mosaic, the first graphical browser, is introduced
                            to the average consumer.`,
  learning_round_table_gathering: "Learning round table gathering",
  incoming_messages: "Incoming messages",
  results: "Results",
  all_orders: "All Orders",
  all_types: "All types",
  buy_crypto: "Buy Crypto",
  sell_crypto: "Sell Crypto",
  transfer_funds: "Transfer Funds",
  filter_results: "Filter results",
  delete_entry: "Are you sure you want to delete this entry?",
  cannot_undo_operation: "You cannot undo this operation.",
  enable_disable: "Միացնել/Անջատել",
  login: "Login",
  fill_fields: "Fill in the fields below to login to your account",
  or_sign_in_with_credentials: "or sign in with credentials",
  serial_number: "Serial number",
  mac_address: "Մակ հասցե",
  weight_empty_bigger_full:
    "Weight empty can't be bigger or equal than weight full",
  full_bottle_weight: "Ամբողջական շշի քաշ(գրամ)",
  empty_bottle_weight: "Դատարկ շշի քաշ(գրամ)",
  volume_milliliter: "Ծավալ(միլիլիտր)",
  crafted_with: "crafted with",
  by: "by",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  last_week: "Last week",
  last_month: "Last month",
  last_quarter: "Last quarter",
  notifications: "Notifications",
  you_have: "You have",
  timeline: "Timeline",
  blinded_by_desire: "Blinded by desire",
  mega_menu: "Mega menu",
  real_estate: "Real Estate",
  applications: "Applications",
  contacts: "Contacts",
  layouts: "Layouts",
  view_documentation: "View documentation",
  view_product_details: "View product details",
  Powerful_admin_dashboard:
    "Powerful admin dashboard templates & ui kits that are easy to use and customize.",
  user: "User",
  city: "City",
  home: "Գլխավոր",
  good_types: "Ապրանքի տեսակներ",
  good_type: "Ապրանքի տեսակ",
  remember_me: "Remember me",
  crypto_components: "Crypto Components",
  buttons: "Կոճակներ",
  first_ever_iPod: "First ever iPod launches.",
  latest_reports_available: "Latest reports available",
  browse: "Browse",
  file_will_be_uploaded: "file will be uploaded",
  all_files_will_be_uploaded: "All files will be uploaded!",
  some_files_will_be_rejected: "Some files will be rejected!",
  drag_and_drop: "Drag and drop",
  file: "file",
  files: "files",
  jpg_png_images: "(jpg/png images)",
  for_upload: "for upload",
  chosen: "Chosen",
  will_appear_here: "will appear here!",
  success: "success",
  files_click: "files! click",
  tables: "Tables",
  change_password: "Փոխել գաղտնաբառը",
  title: "Վերնագիր",
  inputs: "Մուտքագրումներ",
  question: "Հարց",
  answer: "Պատասխան",
  secondary: "Երկրորդական վերնագիր",
  play_market: "Play market",
  app_store: "App store",
  description: "Նկաարագրություն",
  average_density: "Միջին խտություն",
  density: "Խտություն",
  volume: "Ծավալ",
  litre: "լիտր",
  all_fields_are_required: "Բոլոր դաշտերը պարտադիր են",
  forgot_password: "Մոռացե՞լ եք գաղտնաբառը",
  email_is_not_valid: "Էլ. հասցեն վավեր չէ",
  user_invite_language: "Ընտրեք օգտագործողի հրավերի հաղորդագրության լեզուն",
  password_characters: "Գաղտնաբառը պետք է պարունակի առնվազն 6 նիշ",
  check_your_email: "Ստուգեք ձեր Էլ. հասցեն",
  reset: "Հաստատել",
  settings: "Կարգավորումներ",
  inventory: "Գույքագրում",
  empty_branches: "Մասնաճյուղերը բացակայում են , խնդրում ենք փորձել նորից",
  in_process: "Ընթացքի մեջ է",
  bar_title: "Բարի անվանումը",
  no_inventories:
    "Գույքագրումներ դեռ չկան։Ցանկանու՞մ եք ստեղծել նոր գույքագրում։",
  complete_inventory: "Ավարտել գույքագրումը",
  export_inventory: "Արտածել գույքագրումը",
  export_as: "Արտածել որպես",
  edit: "Խմբագրել",
  edit_profile: "Խմբագրել անձնական էջը",
  choose_from_photo_library: "Ընտրել նկարների  ցանկից",
  take_from_camera: "Նկարել",
  user_info_successfully_updated:
    "Օգտագործողի տվյալները հաջողությամբ թարմացվել են",
  first_name_empty: "Գրեք ձեր անունը",
  last_name_empty: "Գրեք ձեր ազգանունը",
  title_empty: "Վերնագիրը չի կարող դատարկ լինել",
  edit_profile_photo: "Խմբագրել պրոֆիլի լուսանկարը",
  first_name: "Անուն",
  last_name: "Ազգանուն",
  change: "Փոխել",
  current_password: "Ընթացիկ գաղտնաբառ",
  new_password: "Նոր գաղտնաբառ",
  confirm_password: "Հաստատել գաղտնաբառը",
  something_went_wrong: "Ինչ-որ բան սխալ է",
  litrage: "Տարողունակություն",
  measuring_time: "Ժամանակ",
  time: "ժամ",
  bottles_qty: "Շշերի քանակ",
  delete_inventory: "Ջնջել գույքագրումը",
  edit_inventory: "Խմբագրել գույքագրումը",
  leftover: "Մնացորդ",
  delete_the_inventory: "Դուք վստա՞հ եք, որ ցանկանում եք ջնջել գույքագրումը։",
  bottles: "Շշեր",
  bottles_count: "Շշերի քանակ",
  bottle_weight: "Շշի քաշ (գր.)",
  consider_bottle_barcode: "Խնդրում ենք սկանավորեք շտրիխ կոդը",
  valid_bottles_count: "Խնդրում ենք մուտքագրել շշերի վավեր քանակ:",
  valid_bottle_weight: "Խնդրում ենք մուտքագրել շշի վավեր քաշ:",
  weight_empty_weight: "Շշի քաշը չի կարող դատարկ շշի քաշից պակաս լինել:",
  weight_full_weight: "Շշի քաշը չի կարող ավել լինել ամբողջական շշի քաշից:",
  good_not_exists: "Ապրանքատեսակը գոյություն չունի",
  barcode_doesnt_exist:
    "Տվյալ շտրիխ կոդով ապրանքատեսակ գոյություն չունի։ Կցանկանա՞ք ստեղծել այն:",
  inventory_result_successfully: "Կշռումը հաջողությամբ ավելացվել է",
  open_bottle_weight: "Բաց շշի քաշ (գր)",
  open_bottle_weight_kg: "Բաց շշի քաշ(կգ)",
  quantity: "Քանակ",
  pairing_status: "Միացման կարգավիճակ",
  connected: "Միացված է",
  not_connected: "Միացված չէ",
  bluetooth_is_off: "Bluetooth-ն անջատված է",
  please_turn_on_bluetooth: "Խնդրում ենք միացնել Bluetooth-ը",
  scan_barcode: "Սկանավորեք շտրիխ կոդը",
  turn_on_bluetooth: "TՄիացնել Bluetooth-ը",
  connect_to_scale: "Միացեք Կշեռքին",
  start_scaling: "Կշռել",
  check_the_scale: "Ստուգեք կշեռքը",
  permission_not_allowed: "Թույլատրված չէ",
  allow_now: "Թույլատրե՞լ հիմա:",
  yes: "Այո",
  no: "Ոչ",
  sure_complete_inventory:
    "Դուք վստա՞հ եք, որ ցանկանում եք ավարտել գույքագրումը։",
  litres: "լ",
  milliliters: "մլ",
  no_scaling_yet: "Տվյալ գույքագրումների համար դեռ չափումներ չկան",
  search: "Որոնում",
  search_goods_name: "Որոնել ըստ ապրանքատեսակի անվանման",
  bottle_volume_milliliter: "Շշի ծավալ (մլ)",
  full_bottle_weight_kg: "Ամբողջական շշի քաշ(կգ)",
  empty_bottle_weight_kg: "Դատարկ շշի քաշ(կգ)",
  select_type: "Ընտրել տեսակը",
  density_g_cm: "Խտություն(գր/սմ³)",
  density_kg_m: "Խտություն(կգ/մ³)",
  volume_liter: "Ծավալ(լ)",
  create_good: "Ստեղծել ապրանք",
  create_good_success: "Ապրանքը հաջողությամբ ստեղծվել է",
  update_good_success: "Ապրանքը հաջողությամբ թարմացվել է",
  good_already_exist: "Խմիչքն արդեն գոյություն ունի",
  good: "Ապրանք",
  empty_current_password: "Ընթացիկ գաղտնաբառը չի կարող դատարկ լինել",
  empty_new_password: "Նոր գաղտնաբառը չի կարող դատարկ լինել",
  empty_confirm_password: "Հաստատման գաղտնաբառը չի կարող դատարկ լինել",
  passwords_doesnt_match: "Գաղտնաբառերը չեն համընկնում",
  password_updated: "Գաղտնաբառը հաջողությամբ փոխվել է",
  on: "Միացնել",
  off: "Անջատել",
  autosave: "Ավտոմատ պահպանում",
  weight: "Քաշ",
  language: "Լեզու",
  milliliter: "միլիլիտր",
  connect_scale_bluetooth: "Միացեք կշեռքին՝ օգտագործելով Bluetooth-ը",
  Place_bottle_scale: "Տեղադրեք շիշը կշեռքի վրա",
  scan_bottle_barcode: "Սկանավորեք շտրիխ կոդը",
  report_scaling_result: "Ստացեք կշռման արդյունքը",
  ok: "Լավ",
  edited: "Խմբագրված",
  deleted: "Ջնջված",
  unmodified: "Չփոփոխված",
  add_full_bottles: "Ամբողջական շշեր",
  disconnected: "Անջատված է",
  g: "գր",
  kg: "կգ",
  g_cm: "գր/սմ³",
  kg_m: "կգ/մ³",
  are_you_sure: "Դուք վստա՞հ եք",
  account_activation: "Հաշվի ակտիվացում",
  enter_password_account: "Ձեր հաշվի ակտիվացման համար մուտքագրեք գաղտնաբառը",
  email_isnt_verified: "Էլեկտրոնային հասցեն հաստատված չէ,տեղի ունեցելսխալ",
  are_you_sure_you_want_to: "Վստա՞հ եք, որ ցանկանում եք ",
  activate: "Ակտիվացնել",
  deactivate: "Ապաակտիվացնել",
  user_information: "Օգտագործողի տվյալները",
  registration_date: "Գրանցման ամսաթիվը",
  company_information: "Ընկերության տվյալներ",
  register_number: "Գրանցման համարը",
  remaining_branches: "Հասանելի մասնաճյուղեր:",
  requested: "Հայցված",
  canceled: "Չեղարկված",
  close: "Փակել",
  deactivated: "Ապաակտիվացված",
  measurement: "Չափման միավոր",
  branch_prev_month: "Նախորդ ամիս",
  branch_current_month: "Այս ամիս",
  media: "Մեդիա",
  support: "Աջակցություն",
  link: "Հղում",
  icon: "Պատկերակ",
  change_email: "Փոխել էլ - փոստը",
  advertisements: "Advertisements",
  start_date: "Start Date",
  end_date: "End Date",
  placement: "Placement",
  countries: "Countries",
  required_fields_error_msg:
    "Please complete all required fields in every language.",
  start_end_time_error_msg: "Please select both the start and end times.",
  image_error_msg: "Image for English language is required",
  country_error_msg: "Please select at least one country",
  image_size_error_msg: "Image size cannot exceed 5MB",
};
