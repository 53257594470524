export const localizationRussian = {
  about_us: "О Нас",
  about: "Основное",
  how_it_work: "Как пользоваться",
  how_it_works: "Как пользоваться",
  contacts_us: "Связаться с нами",
  faq: "ЧЗВ",
  dashboard: "Панель приборов",
  log_out: "Выйти",
  product: "Продукт",
  terms_of_use: "Условия эксплуатации",
  privacy_policy: "Политика конфиденциальности",
  mobile_app: "Мобильное приложение",
  management_tool: "Инструменты управления ",
  bar_control: "Bar Control",
  owner_operator_company_name: "Owner Operator/Company Name",
  e_mail: "E-Mail",
  phone_number: "Номер телефона",
  comment: "Comment",
  request_a_callback: "Request a Callback",
  frequently_asked_questions: "Frequently Asked Questions",
  branch_management: "Управление филиалом",
  branches: "Ветви",
  request_for_new_branch: "Запрос на новую ветку",
  manager_invitations: "Приглашения менеджера",
  managers: "Менеджеры",
  manager: "Менеджер",
  barmen_list: "Список барменов",
  barmen: "Бармен",
  goods_type: "Тип товаров",
  goods: "Товары",
  inventories: "Инвентаризации",
  navigation_menu: "Меню навигации",
  items: "Список",
  name: "Название",
  phone: "Номер телефона",
  email: "Электронная почта",
  thank_you_email_is_verified: "Спасибо.Электронная почта подтверждена",
  address: "Адрес",
  latitude: "Широта",
  longitude: "Долгота",
  actions: "Действия",
  action: "Действия",
  active: "Активный",
  inactive: "Неактивный",
  not_active: "not active",
  branch_requests: "Запросы филиалов",
  create: "Создать",
  requested_branches_count: "Количество запрошенных ветвей",
  message: "Сообщение",
  status: "Статус",
  barcode: "Штрих код",
  full_bottle: "Full Bottle",
  bottle_count: "Bottle count",
  left_over_ml: "Left over (ml)",
  created_at: "Создано в",
  updated_at: "Updated at",
  deleted_at: "Deleted at",
  count_of_bottles: "Count of bottles",
  left_over: "Left over",
  save: "Сохранить",
  save_filter: "Save filter",
  sign_in: "Войти",
  sign_up: "Подписаться",
  role: "Роль",
  branch_name: "Название филиала",
  created: "Созданный",
  full_name: "Full Name",
  company_name: "Название компании",
  company_business_address: "Company business Address",
  branch_count: "Количество Филиалов",
  company_email: "Company Email",
  tax_code: "Налоговый код",
  registration_number: "Registration Number (optional)",
  goods_types: "Тип товаров",
  weight_full: "Полный  вес",
  weight_empty: "Пустой вес",
  select_branch: "Выберите филиал",
  type: "Вид",
  in_progress: "In progress",
  complete: "завершить",
  branch: "Ветвь",
  order: "Заказ",
  newest: "Newest",
  oldest: "Oldest",
  email_address: "Email Address",
  fill_the_address: "Fill the address",
  search_terms: "Search terms...",
  search_orders: "Search orders...",
  password: "Пароль",
  repeat_password: "Repeat password",
  token_is_expired: "Срок действия токена истек",
  goods_name: "Название товара",
  restaurant_name: "Название ресторана",
  scales: "Весы",
  all_statuses: "All statuses",
  pending: "В ожидании",
  completed: "Завершенный",
  rejected: "Отклоненный",
  processing: "Processing",
  cancelled: "Cancelled",
  branches_count: "Количество филиалов",
  date: "Дата",
  details: "Подробности",
  goods_requests: "Запросы товаров ",
  good_detail: "Детали товара",
  s_n: "S/N",
  manufacturer: "Производитель",
  production_date: "Дата производсва",
  mac: "Мак",
  is_active: "Активный",
  change_user_status: "Are you sure you want to change the user status?",
  reject: "Отклонить",
  accept: "Принять",
  text: "Текст",
  contact: "Contact",
  view: "Посмотреть",
  image: "Image",
  slides: "Slides",
  close_drawer: "Close drawer",
  bamburgh_pro: "Bamburgh React Admin Dashboard with Material-UI PRO",
  collapse_sidebar: "Свернуть",
  expand_sidebar: "Развернуть",
  cancel: "Отменить",
  want_to_reject: "Вы уверены ,что хотите отклонить?",
  want_to_accept: "Вы уверены ,что хотите принять?",
  confirmed: "Подтверждено",
  analytics: "Analytics",
  statistics: "Statistics",
  overview: "Overview",
  back_to_dashboard: "Back to dashboard",
  chat: "Chat",
  calendar: "Calendar",
  file_manager: "File Manager",
  projects: "Projects",
  profile: "Профиль",
  profile_completion: "Profile completion",
  monitoring_dashboard: "Monitoring Dashboard",
  analytics_dashboard: "Analytics Dashboard",
  statistics_dashboard: "Statistics Dashboard",
  view_profile: "View profile",
  web_content: "Веб контент",
  users: "Пользователи",
  messages: "Сообщения",
  terms_and_conditions: "Общие положения и условия",
  main_slider: "Основная панель",
  benefit_section: "Раздел польза",
  steps_section: "Секция шагов",
  image_section: "Раздел изображения",
  items_section: "Раздел наименование",
  clients_section: "Клиентский раздел",
  mobile_section: "Мобильная секция",
  management_section: "Раздел управления",
  contact_section: "Раздел контактов",
  footer_section: "Нижний раздел",
  learn_more: "Learn more",
  accountant_profile: "This is an accountant profile",
  logout: "Logout",
  tasks: "Tasks",
  reports: "Reports",
  stats: "Stats",
  server_stats: "Server Stats",
  view_more_items: "View more items",
  sales_target: "Today's sales target",
  daily_statistics: "Daily statistics regarding your sales targets",
  generate_report: "Generate report",
  view_complete_report: "View complete report",
  mistaken_idea: "But I must explain to you how all this mistaken idea.",
  wonderful_serenity: "A wonderful serenity has taken possession.",
  presidential_scandal: `Bill Clinton's presidential scandal makes it
  online.`,
  java_exam_day: "Java exam day",
  business_investor_meeting: "Business investor meeting",
  new: "New",
  graphical_browser: `Mosaic, the first graphical browser, is
                                introduced to the average consumer.`,
  own_image: "Who formed us in his own image, and the breath.",
  dummy_text_acting: "This is a dummy text acting ...",
  files_ready: "Files ready",
  add: "Add",
  decrease: "decrease",
  cards_examples: "Cards examples",
  form_wizards: "Form wizards",
  pricing_tables: "Pricing tables",
  dashboards: "Dashboards",
  there_are: "There are",
  multiple: "multiple",
  dashboard_layouts_available: "dashboard layouts available!",
  helpdesk: "Helpdesk",
  crm_ui: "CRM UI",
  customers: "Клиенты",
  customer: "Клиент",
  delete: "Удалить",
  menu_box: "This menu box is scrollable (sm)",
  services: "Services",
  others: "Others",
  components: "Components",
  view_details: "View details",
  web_developer: "Web developer W",
  javascript: "Javascript",
  angular: "Angular",
  incapable_of_drawing: `I should be incapable of drawing a single stroke at the
                  present moment; and yet I feel that I never was a greater
                  artist than now.`,
  team_members: "Team members",
  view_complete_profile: "View complete profile",
  wonderful_serenity_has_possession: "Wonderful serenity has possession",
  please_wait_while_load: "Please wait while we load the live preview examples",
  preview_instance: `This live preview instance can be slower than a real
                                production build!`,
  add_new_entry: "Add new entry",
  stay_up_to_date: "Stay up to date",
  subscribe_to_stay_up_to_date:
    "Subscribe to stay up to date with our latest releases and updates!",
  results_per_page: "results per page",
  ascending: "Ascending",
  descending: "Descending",
  order_details: "Order details",
  source: "Source",
  order_id: "Order ID",
  balance: "Balance",
  amount: "Amount",
  fiat_deposit: "Fiat deposit",
  bank_account: "Bank Account",
  deposit: "Deposit",
  paypal_withdraw: "Paypal withdraw",
  crypto_wallet: "Crypto Wallet",
  my_bitcoin_wallet: "My Bitcoin Wallet",
  withdraw: "Withdraw",
  current_month: "Current month",
  your_daily_tasks_list: "Your daily tasks list",
  finish_tasks_for_today: "Finish tasks for today",
  submit_report: "Submit Report",
  get_in_touch: "Get in touch",
  MySpace_network: "MySpace becomes the most popular social network.",
  pending_actions: "You have pending actions to take care of.",
  total_sales: "Total Sales",
  total_performance: "Total performance for selected period",
  world_among_the_stalks: "World among the stalks",
  deploy_new_website: "Deploy new website",
  comments_available: "There are 653 new comments available!",
  latest_sales: "Latest sales",
  increase: "Increase",
  last_year: "Last year",
  new_messages: "new messages",
  world_wide_web: `The World Wide Web goes live with its first web
                            page.`,
  clinton_presidential: "Bill Clinton's presidential scandal makes it online.",
  first_graphical_browser: `Mosaic, the first graphical browser, is introduced
                            to the average consumer.`,
  learning_round_table_gathering: "Learning round table gathering",
  incoming_messages: "Incoming messages",
  results: "Results",
  all_orders: "All Orders",
  all_types: "All types",
  buy_crypto: "Buy Crypto",
  sell_crypto: "Sell Crypto",
  transfer_funds: "Transfer Funds",
  filter_results: "Filter results",
  delete_entry: "Are you sure you want to delete this entry?",
  cannot_undo_operation: "You cannot undo this operation.",
  enable_disable: "Включить/Выключить",
  login: "Login",
  fill_fields: "Fill in the fields below to login to your account",
  or_sign_in_with_credentials: "or sign in with credentials",
  serial_number: "Серийный номер",
  mac_address: "Мак адрес",
  weight_empty_bigger_full:
    "Weight empty can't be bigger or equal than weight full",
  full_bottle_weight: "Вес полной бутылки(грамм)",
  empty_bottle_weight: "Вес пустой бутылки(грамм)",
  volume_milliliter: "Объем (миллилитр)",
  crafted_with: "crafted with",
  by: "by",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  last_week: "Last week",
  last_month: "Last month",
  last_quarter: "Last quarter",
  notifications: "Notifications",
  you_have: "You have",
  timeline: "Timeline",
  blinded_by_desire: "Blinded by desire",
  mega_menu: "Mega menu",
  real_estate: "Real Estate",
  applications: "Applications",
  contacts: "Contacts",
  layouts: "Layouts",
  view_documentation: "View documentation",
  view_product_details: "View product details",
  Powerful_admin_dashboard:
    "Powerful admin dashboard templates & ui kits that are easy to use and customize.",
  user: "User",
  city: "City",
  home: "Главная",
  good_types: "Виды товаров",
  good_type: "Тип товаров",
  remember_me: "Remember me",
  crypto_components: "Crypto Components",
  buttons: "Buttons",
  first_ever_iPod: "First ever iPod launches.",
  latest_reports_available: "Latest reports available",
  browse: "Browse",
  file_will_be_uploaded: "file will be uploaded",
  all_files_will_be_uploaded: "All files will be uploaded!",
  some_files_will_be_rejected: "Some files will be rejected!",
  drag_and_drop: "Drag and drop",
  file: "file",
  files: "files",
  jpg_png_images: "(jpg/png images)",
  for_upload: "for upload",
  chosen: "Chosen",
  will_appear_here: "will appear here!",
  success: "success",
  files_click: "files! click",
  tables: "Tables",
  change_password: "Изменить пароль",
  title: "Заголовок",
  inputs: "Inputs",
  question: "Вопрос",
  answer: "Ответ",
  secondary: "Подзаголовок",
  play_market: "Play market",
  app_store: "App store",
  description: "Описание",
  average_density: "Средняя плотность",
  density: "Плотность",
  volume: "Объем",
  litre: "литр",
  all_fields_are_required: "Все поля обязательны для заполнения",
  forgot_password: "Забыли пароль?",
  email_is_not_valid: "Электронный адрес почты недействителен.",
  password_characters:
    "Пароль недействителен должен содержать 6 или более символов.",
  check_your_email: "Проверьте свою электронную почту.",
  reset: "Сброс",
  settings: "Настройки",
  inventory: "Инвентаризация",
  empty_branches: "Пустые филиалы, попробуйте еще раз.",
  in_process: "в процессе",
  bar_title: "Название бара",
  no_inventories: "Еще нет инвентаризации. Хотите создать инвентаризацию??",
  complete_inventory: "Завершить инвентаризацию",
  export_inventory: "Экспортировать инвентаризацию",
  export_as: "Экспортировать как",
  edit: "Редактировать",
  edit_profile: "Редактировать профиль",
  choose_from_photo_library: "Выберите из галереи",
  take_from_camera: "Фотографировать",
  user_info_successfully_updated: "Информация о пользователе успешно обновлена",
  first_name_empty: "Имя не может быть пустым",
  last_name_empty: "Фамилия не может быть пустой",
  title_empty: "Заголовок не может быть пустым",
  edit_profile_photo: "Редактировать фото профиля",
  first_name: "Имя",
  last_name: "Фамилия",
  change: "Изменить",
  current_password: "Текущий пароль",
  new_password: "Новый пароль",
  confirm_password: "Подтвердите пароль",
  something_went_wrong: "Ошибка",
  litrage: "Литраж",
  measuring_time: "Время измерения",
  time: "Время",
  bottles_qty: "Количество бутылок",
  delete_inventory: "Удалить инвентаризацию",
  edit_inventory: "Редактировать инвентаризацию",
  leftover: "Остаток",
  delete_the_inventory: "Вы уверены, что хотите удалить инвентаризацию?",
  bottles: "Бутылки",
  bottles_count: "Количество бутылок",
  bottle_weight: "Вес бутылки (грамм)",
  consider_bottle_barcode:
    "Штрих-код не просканирована, пожалуйста просканируйте штрих-код.",
  valid_bottles_count: "Введите допустимое количество бутылки.",
  valid_bottle_weight: "Введите действительный вес бутылки.",
  weight_empty_weight: "Вес бутылки не может быть меньше веса пустой бутылки.",
  weight_full_weight: "Вес бутылки не может быть больше веса полной бутылки.",
  good_not_exists: "Напиток не существует",
  barcode_doesnt_exist:
    "Товара со штрих-кодом не существует. Создать новый товар?",
  inventory_result_successfully: "Результат взвешивания был успешно добавлен.",
  open_bottle_weight: "Вес открытой бутылки (грамм)",
  open_bottle_weight_kg: "Вес открытой бутылки (килограмм)",
  quantity: "Количество",
  pairing_status: "Статус подключения",
  connected: "Подключено",
  not_connected: "Нет подключения",
  bluetooth_is_off: "Bluetooth выключен",
  please_turn_on_bluetooth: "Пожалуйста, включите Bluetooth",
  scan_barcode: "Просканируйте штрих-код",
  turn_on_bluetooth: "Включите Bluetooth",
  connect_to_scale: "Подключиться к весам",
  start_scaling: "Начать взвешивание",
  check_the_scale: "Проверить весы",
  permission_not_allowed: "Нет разрешения",
  allow_now: "Разрешить сейчас?",
  yes: "Да",
  no: "Нет",
  sure_complete_inventory: "Вы уверены, что хотите завершить инвентаризацию?",
  litres: "л",
  milliliters: "мл",
  no_scaling_yet: "Нет данных по данной инвентаризации",
  search: "Поиск",
  search_goods_name: "Поиск по названию продукта",
  bottle_volume_milliliter: "Объем бутылки (миллилитр)",
  full_bottle_weight_kg: "Вес полной бутылки (килограмм)",
  empty_bottle_weight_kg: "Вес пустой бутылки (килограмм)",
  select_type: "Выберите тип",
  density_g_cm: "Плотность(г/см³)",
  density_kg_m: "Плотность(кг/м³)",
  volume_liter: "Объем(литр)",
  create_good: "Создать товар",
  create_good_success: "Товар был успешно создан.",
  update_good_success: "Товар был успешно редактирован.",
  good_already_exist: "Товар уже существует",
  good: "Товар",
  empty_current_password: "Текущий пароль не может быть пустым",
  empty_new_password: "Новый пароль не может быть пустым",
  empty_confirm_password: "Подтверждение пароля не может быть пустым",
  user_invite_language: "Выберите язык сообщения для приглашение пользователя",
  passwords_doesnt_match: "Пароли не совпадают",
  password_updated: "Пароль успешно изменен",
  on: "Включить",
  off: "Выключить",
  autosave: "Автосохранение",
  weight: "Вес",
  language: "Язык",
  milliliter: "миллилитр",
  connect_scale_bluetooth: "Подключитесь к весам через Bluetooth",
  Place_bottle_scale: "Поставьте бутылку на весы",
  scan_bottle_barcode: "Просканируйте штрих-код",
  report_scaling_result: "Отчет о результате взвешивания",
  ok: "Ok",
  edited: "Отредактировано",
  deleted: "Удалено",
  unmodified: "Без изменений",
  add_full_bottles: "Полные бутылки",
  disconnected: "Отключен",
  g: "гр",
  kg: "кг",
  g_cm: "г/см³",
  kg_m: "кг/м³",
  are_you_sure: "Вы уверены ?",
  account_activation: "Активация аккаунта",
  enter_password_account: "Введите пароль для активации вашего аккаунта",
  email_isnt_verified: "Электронная почта не подтверждена,произошла ошибка ",
  are_you_sure_you_want_to: "Вы уверены, что хотите ",
  activate: "Активировать",
  deactivate: "Деактивировать",
  user_information: "Информация о пользователе",
  registration_date: "Дата регистрации",
  company_information: "Информация о компании",
  register_number: "Регистрационный код",
  remaining_branches: "Доступное количество филиалов:",
  requested: "Запрошено",
  canceled: "Отменено",
  close: "Закрыть",
  deactivated: "Деактивировано",
  measurement: "Единица измерения",
  branch_prev_month: "Прошлый месяц",
  branch_current_month: "Этот месяц",
  media: "Медиа",
  support: "Поддерживать",
  link: "Ссылка",
  icon: "Иконка",
  change_email: "Изменить e-mail",
  advertisements: "Advertisements",
  start_date: "Start Date",
  end_date: "End Date",
  placement: "Placement",
  countries: "Countries",
  required_fields_error_msg:
    "Please complete all required fields in every language.",
  start_end_time_error_msg: "Please select both the start and end times.",
  image_error_msg: "Image for English language is required",
  country_error_msg: "Please select at least one country",
  image_size_error_msg: "Image size cannot exceed 5MB",
};
