import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import useStore from "context";
import { SOMETHING_WENT_WRONG } from "Constants";

export const usePutData = (Api, refresh) => {
  const { setSuccessMsg, setErrorMsg } = useStore();
  const location = useLocation();
  const history = useHistory();

  return useCallback(
    async (data, id) => {
      try {
        await Api(data, id);
        if (typeof refresh === "function") {
          refresh();
        }

        if (location.pathname.includes("create")) {
          history.goBack();
        }

        setSuccessMsg("updated");
      } catch (e) {
        setErrorMsg(e.message || e?.content?.[0].msg || SOMETHING_WENT_WRONG);
      }
    },
    [Api, history, location.pathname, refresh, setErrorMsg, setSuccessMsg]
  );
};
