import React, { useCallback, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { Table, Card, TextField, InputAdornment } from "@material-ui/core";
import "./style.scss";

import Pagination from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
import { DragTable } from "components";
import update from "immutability-helper";
import clsx from "clsx";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import { useTranslation } from "localization/translation";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
export default function TableWithFilter({
  titles,
  title,
  fields,
  rows,
  createLink,
  total,
  items,
  setItems,
  noDrag,
  orderCallback,
  header,
  actions = true,
  searchable = false,
  searchHandler,
  limit = 20,
  userPage,
  datePicker = false,
  onDateChange,
}) {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const translation = useTranslation();

  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });

  const [searchOpen, setSearchOpen] = useState(false);

  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);

  const handleDateChange = (event) => {
    const formattedDates = event?.map((dateObject) => {
      const year = dateObject.$y;
      const month = dateObject.$M;
      const day = dateObject.$d.getDate() + 1;
      const date = new Date(year, month, day);

      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    });

    setDate(event);
    onDateChange({
      startDate: formattedDates?.[0],
      endDate: formattedDates?.[1],
    });
  };

  const updatePage = useCallback(
    (e, page) => {
      query.set("page", page);
      history.push(`?${query.toString()}`);
    },
    [history, query]
  );

  const moveCard = (dragIndex, hoverIndex) => {
    const hideDragItem = 1;
    const showPlaceDragItem = 0;
    setItems((items) => {
      const updated = update(items, {
        $splice: [
          [dragIndex, hideDragItem],
          [hoverIndex, showPlaceDragItem, items[dragIndex]],
        ],
      });
      orderCallback(updated);
      return updated;
    });
  };

  return (
    <Card className="card-box mb-spacing-6-x2">
      {searchable && (
        <div
          className={clsx("d-flex align-items-center p-4", {
            "w-100 p-4": searchOpen,
          })}
        >
          <div
            className={clsx("search-wrapper search-wrapper--grow w-100", {
              "is-active": searchOpen,
            })}
          >
            <TextField
              variant="outlined"
              size="small"
              id="input-with-icon-textfield22-2"
              placeholder={translation.search_orders}
              onFocus={openSearch}
              onBlur={closeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => searchHandler(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
        <div className="align-create-btn-between">
          <h6 className="font-weight-bold font-size-lg mb-0 text-black">
            {title}
          </h6>
          {header}
          {!!datePicker && (
            <RangePicker onChange={(date) => handleDateChange(date)} />
          )}
          {!!createLink && (
            <Button
              className="m-2 btn-neutral-primary"
              onClick={() => history.push(`/${createLink}`)}
            >
              {translation.create}
            </Button>
          )}
        </div>
      </div>
      <div className="divider" />
      <div className="divider" />
      <div className="pt-4 px-4">
        <Table className="table table-alternate-spaced mb-0">
          <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
            <tr>
              {titles && (
                <th className="text-left px-4">
                  <span className="mr-3">{translation.create}</span>
                </th>
              )}
              {fields}
              {actions && <th className="text-right">{translation.actions}</th>}
            </tr>
          </thead>
          <tbody className="w100">
            {rows?.map((el, i) =>
              !noDrag ? (
                <DragTable
                  key={items[i]._id}
                  index={i}
                  moveCard={moveCard}
                  node={el}
                />
              ) : (
                <>
                  {userPage ? (
                    el
                  ) : (
                    <tr className={`px-2 py-2 m-2 my-3 font-weight-bold`}>
                      {el}
                    </tr>
                  )}
                  <tr className="divider" />
                </>
              )
            )}
          </tbody>
        </Table>
      </div>
      <div className="divider mt-3" />
      <div className="card-footer p-4 d-flex justify-content-center">
        {total > limit && (
          <Pagination
            page={parseInt(query.get("page")) || 1}
            onChange={updatePage}
            className="pagination-primary"
            count={Math.ceil(parseInt(total) / limit) || 1}
          />
        )}
      </div>
    </Card>
  );
}
