import React, { useState, memo, useEffect } from "react";
import { Card, List, ListItem } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import BackupIcon from "@material-ui/icons/Backup";
import Upload from "./upload";
import Images from "./images";
import useStore from "context";

function Gallery({
  className = "",
  single,
  label,
  value,
  onChange,
  setNewFile,
  setShowImage = null,
  isBenefit,
  accept = "image/jpeg, image/png, image/svg",
  isAdvertisement = false,
  uploadedImage,
}) {
  const [tab, setTab] = useState("img");
  const { gallery, setGallery } = useStore();

  useEffect(() => {
    if (gallery) {
      setTab("img");
    }
  }, [gallery]);

  return (
    <Card className={`my-4 py-3 ${className}`}>
      {label && <h5 className="font-size-lg mb-0 text-center">{label}</h5>}
      <List component="div" className="my-3 nav-tabs nav-tabs-primary d-flex">
        <ListItem
          button
          disableRipple
          selected={tab === "img"}
          onClick={() => {
            setTab("img");
            setGallery(true);
          }}
        >
          <ImageIcon />
          <div className="divider" />
        </ListItem>
        <ListItem
          button
          disableRipple
          selected={tab === "upload"}
          onClick={() => {
            setTab("upload");
            setGallery(false);
          }}
        >
          <BackupIcon />
          <div className="divider" />
        </ListItem>
      </List>
      {tab === "img" && (
        <Images
          images={value}
          setImages={onChange}
          setShowImage={setShowImage}
          isBenefit={isBenefit}
        />
      )}
      {tab === "upload" && (
        <Upload
          single={single}
          setNewFile={setNewFile}
          accept={accept}
          isAdvertisement={isAdvertisement}
          uploadedImage={uploadedImage}
        />
      )}
    </Card>
  );
}

export default memo(Gallery);
