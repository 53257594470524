import React, { memo } from "react";
import CardDrag from "./drag_card";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { Card, IconButton } from "@material-ui/core";
import { imgUrl } from "../service";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexWrap: "wrap",
  },
  box: {
    width: "max-content",
  },
  img: {
    cursor: "move",
    height: 200,
    width: 200,
    objectFit: "cover",
  },
  icon: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 99,
  },
  blackBackground: {
    backgroundColor: "black",
  },
}));

const Images = ({ images, setImages, setShowImage = null, isBenefit }) => {
  const classes = useStyles();

  const moveCard = (dragIndex, hoverIndex) => {
    setImages(
      update(images, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, images[dragIndex]],
        ],
      })
    );
  };

  const handleSetImage = () => {
    setImages(null);
    if (setShowImage) setShowImage({});
  };

  if (images && !Array.isArray(images)) {
    return (
      <Card
        className={
          "card card-box px-2 py-2 m-2 font-weight-bold " + classes.box
        }
      >
        <img
          className={`${classes.img} ${isBenefit && classes.blackBackground}`}
          src={imgUrl + images}
        />
        <IconButton onClick={() => handleSetImage()} className={classes.icon}>
          <ClearIcon />
        </IconButton>
      </Card>
    );
  }

  return (
    <DndProvider backend={Backend}>
      <div className={"col-md-6 my-4 p-0 mx-auto d-flex " + classes.wrapper}>
        {images?.map((card, index) => (
          <CardDrag
            key={card}
            index={index}
            moveCard={moveCard}
            node={
              <>
                {typeof card === "string" ? (
                  <img
                    className={`${classes.img} ${
                      isBenefit && classes.blackBackground
                    }`}
                    src={imgUrl + card}
                  />
                ) : (
                  <img
                    className={`${classes.img} ${
                      isBenefit && classes.blackBackground
                    }`}
                    src={URL.createObjectURL(card)}
                  />
                )}
                <IconButton
                  onClick={() =>
                    setImages(images.filter((e, i) => index !== i))
                  }
                  className={classes.icon}
                >
                  <ClearIcon />
                </IconButton>
              </>
            }
          />
        ))}
      </div>
    </DndProvider>
  );
};

export default memo(Images);
