import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

export const useGetList = (Api, query, limit = 20, isPageCorrect) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState();
  const [total, setTotal] = useState(null);

  const queryUrl = new URLSearchParams(useLocation().search);

  const callback = useCallback(() => {
    if (Api) {
      setLoading(true);
      Api({
        page: isPageCorrect
          ? queryUrl.get("page") || "1"
          : (queryUrl.get("page") || "1") - 1,
        search: queryUrl.get("search"),
        limit: limit,
        ...(query && query),
      })
        .then((res) => {
          setItems(res.data);
          setTotal(res.total);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [Api, query, queryUrl]);

  useEffect(callback, [Api, queryUrl.toString(), query]);

  return [items, total, callback, setItems];
};
