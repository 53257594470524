import Resource from "./resource";
export const imgUrl = process.env.REACT_APP_imagesBaseUrl;
export { default as main } from "./main";

export const goods = new Resource("/v2/admin/goods");
export const goodsType = new Resource("/admin/goods-type");
export const devices = new Resource("/admin/devices");
export const users = new Resource("/admin/users");
export const slider = new Resource("/admin/home/slider");
export const faq = new Resource("/admin/home/faq");
export const aboutContent = new Resource("/admin/home/about/content");
export const requestedBranches = new Resource("/admin/branches/requests");
export const goodsRequests = new Resource("/v2/admin/good-request");
export const privacy = new Resource("/web/privacy-policy");
export const terms = new Resource("/web/terms-and-conditions");
export const support = new Resource("/v2/messenger");

//v2
export const contact = new Resource("/web/v2/home/contact");
export const v2slider = new Resource("/web/v2/home/slider");
export const v2benefit = new Resource("/web/v2/home/benefits");
export const v2steps = new Resource("/web/v2/home/steps");
export const v2infoImage = new Resource("/web/v2/home/info-image-section");
export const v2infoItemsSection = new Resource(
  "/web/v2/home/info-items-section"
);
export const v2ClientsSection = new Resource("/web/v2/home/clients-section");
export const v2mobileSection = new Resource("/web/v2/home/mobile-section");
export const v2managementSection = new Resource(
  "/web/v2/home/management-section"
);
export const v2contactSection = new Resource("/web/v2/home/contact-section");
export const v2footerSection = new Resource("/web/v2/home/footer-section");
export const v2Media = new Resource("/v2/media");

export const advertisement = new Resource("/v2/admin/advertisement");
export const countries = new Resource("/v2/admin/country");
